import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeft } from '../../../assets/icon/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import usePaymentMethod from '../../../hook/usePaymentMethod';
import { useOrderContext } from '../../../context/OrderContext';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useMenuContext } from '../../../context/MenuContext';

declare global {
  interface Window {
    Elepay: any;
  }
}

interface PaymentProps {
  toggleDrawer: (newOpen: boolean) => () => void;
  totalPrice: number;
  orderId?: string;
  restaurant_id?: string;
}

const PAYMENT: React.FC<PaymentProps> = ({ toggleDrawer, totalPrice, orderId, restaurant_id }) => {
  const { t } = useTranslation();
  const elepay_URL = 'https://js.elepay.io/v1/elepay.js';
  const [codeObjectId, setCodeObjectId] = useState<string | null>(null);
  const { payment } = usePaymentMethod();
  const { orderDetails } = useOrderContext();
  const location = useLocation();
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const queryParams = queryString.parse(location.search);
  const { stadiumData, listRestaurantData } = useMenuContext();
  const restaurant = listRestaurantData.find((restaurant) => restaurant.id === restaurant_id);

  const handleBeforeUnload = () => {
    const id = orderId ?? sessionStorage.getItem('orderId');
    if (id) {
      const orderDetailLink = queryString.stringifyUrl({
        url: `/orderdetail/${id}`,
        query: queryParams,
      });
      window.location.href = orderDetailLink;
    }
  };

  useEffect(() => {
    const fetchPaymentCode = async () => {
      try {
        const id = orderId ?? sessionStorage.getItem('orderId');
        const code = await payment(totalPrice, id ?? '', restaurant?.location_id ?? '', restaurant?.merchant_id ?? '');
        setCodeObjectId(code.id);
        setHasFetched(true);
      } catch (error) {
        setCodeObjectId(null);
      }
    };

    if (orderDetails && !hasFetched) {
      fetchPaymentCode();
    }

    window.addEventListener('visibilitychange', handleBeforeUnload);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('visibilitychange', handleBeforeUnload);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const ElepayCheckout: React.FC<{ codeObjectId: string }> = ({ codeObjectId }) => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = elepay_URL;
      script.async = true;
      script.onload = () => {
        const elepay = new window.Elepay(stadiumData.elepay_public_key);
        elepay.checkout(codeObjectId);
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [codeObjectId, hasFetched]);

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-red-500 border-opacity-75"></div>
          <p className="mt-4 text-white">{t('LOADING_PAYMENT')}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="w-screen h-screen bg-black-0">
      <div className="max-w-[672px] mx-auto bg-white h-full">
        <div className="flex w-full sticky top-0 bg-white gap-2 items-center px-4 border-b">
          <button className="p-3" onClick={toggleDrawer(false)}>
            <ArrowLeft />
          </button>
          <p className="text-gray-700 text-base font-medium leading-6">{t('ORDER_PAYMENT_METHOD')}</p>
        </div>
        <div className="py-3 px-4">{codeObjectId && <ElepayCheckout codeObjectId={codeObjectId} />}</div>
      </div>
    </div>
  );
};

export default PAYMENT;
