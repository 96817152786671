import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import 'firebase/firestore';
import { db } from '../../libs/firebase';
import { CreateOrderDTO, FoodItem, Order, User_Rider } from '../../interfaces/types';
import { BillStatus, OrderItemStatus } from '../../consts';
import { v4 as uuidv4 } from 'uuid';
import useCreateOrder from '../../hook/useCreateOrder';
import { getUTCDateStr } from '../../utils';
import db_config from '../../configs/db_config';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGetListUsers from '../../hook/useGetListUsers';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

interface OrderContextType {
  updateOrderDetails: (details: CreateOrderDTO) => void;
  updateSeatId: (id: string) => Promise<string>;
  createOrder: (orderDetails: CreateOrderDTO, orderId: string) => Promise<boolean>;
  orderDetails: CreateOrderDTO;
  setOrderDetails: Dispatch<SetStateAction<CreateOrderDTO>>;
  loadingOrder: boolean;
  orders: Order[];
  orderId: string | undefined;
  errorOrder: any;
}

const OrderContext = createContext<OrderContextType>({
  updateOrderDetails: () => {},
  updateSeatId: async (id: string) => {
    return '';
  },
  orderDetails: {
    order_status: OrderItemStatus.PREPARING,
    order_table_number: '',
    foods: [] as FoodItem[],
    promotion_foods: [] as FoodItem[],
    bill_status: BillStatus.NOT_CLEARED,
    payment_method: '',
    restaurant_id: '',
    promotion_restaurant_id: '',
    seat_type: '',
    seat_number: '',
  },
  setOrderDetails: () => {},
  loadingOrder: false,
  orders: [],
  orderId: undefined,
  errorOrder: false,
  createOrder: async () => {
    return false;
  },
});

export const useOrderContext = () => React.useContext(OrderContext);

export const OrderProvider: React.FC = ({ children }) => {
  const [riders, setRiders] = useState<{ [id: string]: User_Rider }>({});
  const [orders, setOrders] = useState<Order[]>([]);
  const { t } = useTranslation();
  const max_id = 9999;
  const [orderDetails, setOrderDetails] = useState<CreateOrderDTO>({
    order_status: OrderItemStatus.PREPARING,
    order_table_number: '',
    foods: [] as FoodItem[],
    promotion_foods: [] as FoodItem[],
    bill_status: BillStatus.NOT_CLEARED,
    payment_method: '',
    restaurant_id: '',
    seat_type: '',
    seat_number: '',
  });
  const [orderId, setOrderId] = useState<string>();
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [loadingRiders, setLoadingRiders] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { data: listUsers, isLoading } = useGetListUsers();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const handleCloseDialog = async () => {
    localStorage.removeItem('received_order');
    const currentOrderId = sessionStorage.getItem('orderId');
    const seatID = queryParams.seatID as string;
    let newOrderId = '';
    if (!currentOrderId || orders.findIndex((o) => o.id === currentOrderId) > -1) {
      newOrderId = await updateSeatId(seatID);
    } else {
      newOrderId = currentOrderId;
    }
    const neworderDetailLink = queryString.stringifyUrl({
      url: `/neworder/${newOrderId}`,
      query: { ...queryParams },
    });
    navigate(neworderDetailLink);

    setOpenDialog(false);
  };

  const { createOrder, errorOrder } = useCreateOrder();

  const updateOrderDetails = (details: CreateOrderDTO) => {
    setOrderDetails(details);
  };

  const updateSeatId = async (id: string) => {
    const orderNewId = await generateOrderId(id);
    setOrderId(orderNewId);
    sessionStorage.setItem('orderId', orderNewId);
    return orderNewId;
  };

  const updateLatestIdInDb = async (stadium_id: string, menyOrderId: string, dateStr: string) => {
    await db.collection(db_config.DB_TABLE_STADIUM_MANAGE).doc(stadium_id).update({
      latest_id: menyOrderId,
      last_update_date: dateStr,
    });
  };

  const generateOrderId = async (id: string) => {
    const dateStr = getUTCDateStr();
    const stadium_id = sessionStorage.getItem('stadiumID');
    if (!stadium_id) {
      const randomId = uuidv4().slice(0, 8);
      return `${id}-${randomId}`;
    }

    const orderRef = await db.collection(db_config.DB_TABLE_STADIUM_MANAGE).doc(stadium_id).get();
    const data = orderRef.data();
    let latest_id = data?.latest_id ?? 0;
    const lastUpdateDate = data?.last_update_date ?? dateStr;
    if (lastUpdateDate !== dateStr) {
      latest_id = 0;
    }

    let menyOrderId = latest_id + 1;

    if (menyOrderId > max_id) {
      menyOrderId = 1;
    }
    const menyOrderIdStr = menyOrderId.toString().padStart(4, '0');

    await updateLatestIdInDb(stadium_id, menyOrderId, dateStr);

    return `${dateStr}-${id}-${menyOrderIdStr}`;
  };

  const getAllRider = async () => {
    setLoadingRiders(true);
    if (!listUsers) return;
    try {
      const users = await db.collection(db_config.DB_TABLE_STADIUM_MANAGE_USERS).where('role', '==', 'RIDER').get();
      const configSnapshot = await db.collection(db_config.DB_TABLE_STADIUM_CONFIGURATION).doc(db_config.DB_TABLE_STADIUM_CONFIGURATION).get();
      const configData = configSnapshot.data();
      const data: any = {};
      users.docs.forEach((user) => {
        const rider = user.data();
        if (rider?.user_name) {
          const userInfo = listUsers.find((u: any) => u.uid === user.id);
          const gender = userInfo?.gender ?? 0;
          const imageUrl = configData?.rider_url?.[gender] ?? null;
          const riderInfor = {
            name: rider.user_name,
            image: imageUrl,
          };
          data[user.id] = riderInfor;
        }
      });

      setRiders(data);
    } catch (error) {
      console.error('Error stadium_manage_users: ', error);
    } finally {
      setLoadingRiders(false);
    }
  };

  const getOrderHistory = async (user_uuid: string) => {
    try {
      setLoadingOrder(true);
      const unsubscribe = db
        .collection(db_config.DB_TABLE_ALL_ORDERS)
        .where('ordered_by', '==', user_uuid)
        .orderBy('order_create_time', 'desc')
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.size) {
            const orderHistory: any = [];
            querySnapshot.docChanges().forEach((_change) => {
              if (_change.type === 'modified') {
                const data = _change.doc.data();
                if (data.order_status === OrderItemStatus.DELIVERED) {
                  localStorage.setItem('received_order', 'active');
                  setOpenDialog(true);
                }
              }
            });

            querySnapshot.forEach((doc) => {
              const order = doc.data();
              if (order.is_damaged === true) return;
              let rider = riders[order?.rider_id] ?? null;

              orderHistory.push({ ...order, id: doc.id, rider });
            });

            setOrders(orderHistory);
          }
          setLoadingOrder(false);
        });
      return unsubscribe;
    } catch (error) {
      setLoadingOrder(false);
      console.error('error:', error);
    }
  };

  useEffect(() => {
    getAllRider();
  }, [listUsers, isLoading]);

  useEffect(() => {
    const user_uuid = localStorage.getItem('uuid');
    const received_order = localStorage.getItem('received_order');

    const fetchOrderHistory = async () => {
      if (received_order === 'active') {
        setOpenDialog(true);
      }
      if (!user_uuid) {
        const uuid = uuidv4();
        localStorage.setItem('uuid', uuid);
        await getOrderHistory(uuid);
      } else {
        await getOrderHistory(user_uuid);
      }
    };

    if (!loadingRiders) {
      fetchOrderHistory();
    }

    return () => {};
  }, [loadingRiders]);

  return (
    <OrderContext.Provider
      value={{
        updateOrderDetails,
        createOrder,
        updateSeatId,
        orderDetails,
        setOrderDetails,
        loadingOrder,
        orders,
        errorOrder,
        orderId,
      }}
    >
      {children}
      <Dialog open={openDialog} sx={{ '& .MuiPaper-root': { borderRadius: '12px', width: '100%' } }}>
        <div className="p-6 flex flex-col gap-6 w-full items-center">
          <p className="text-black-900 text-lg font-semibold leading-7 text-center">{t('RECEIVED_ORDER')}</p>
          <button
            className={`px-18 py-2.5 rounded-lg border border-solid shadow-button text-base font-semibold leading-6 bg-primary-green border-primary-green text-white`}
            onClick={handleCloseDialog}
          >
            {t('RECEIVED_ORDER_BUTTON')}
          </button>
        </div>
      </Dialog>
    </OrderContext.Provider>
  );
};
