import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import UpdateIcon from '@mui/icons-material/Update';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TABS } from '../../consts';
import HomeTab from './components/HomeTab/HomeTab';
import OrderHistoryTab from './components/OrderHistoryTab/OrderHistoryTab';
import { useMenuContext } from '../../context/MenuContext';

const NewOrder: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActiveTab, activeTab } = useMenuContext();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const handleTabChange = (tab: TABS) => {
    if (tab === TABS.HOME) {
      const currentParams = new URLSearchParams(searchParams);
      currentParams.delete('tab');
      navigate(`?${currentParams.toString()}`);
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    if (tab === TABS.ORDER_HISTORY) {
      setActiveTab(TABS.ORDER_HISTORY);
    } else {
      setActiveTab(TABS.HOME);
    }
  }, [tab]);

  const onStartOrder = () => {
    setActiveTab(TABS.HOME);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case TABS.HOME:
        return <HomeTab />;
      case TABS.ORDER_HISTORY:
        return <OrderHistoryTab onStartOrder={onStartOrder} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex justify-center items-center bg-black-0 overflow-hidden">
      <div className="relative h-full w-full max-w-2xl bg-white">
        {renderTabContent()}
        <div className="flex flex-col justify-center items-end fixed bottom-0 max-w-2xl w-full">
          <div
            className="h-1 bg-green-500 transition-transform duration-300 left-0 top-0 absolute"
            style={{ width: '50%', transform: activeTab === TABS.HOME ? 'translateX(0)' : 'translateX(100%)' }}
          />
          <div className="flex w-full bg-white flex-grow">
            <button
              className={`w-1/2 ${
                activeTab === TABS.HOME ? 'text-green-500 font-semibold' : 'text-gray-400 font-medium'
              } flex flex-col items-center text-sm leading-5 px-2 py-10`}
              onClick={() => handleTabChange(TABS.HOME)}
            >
              <HomeOutlinedIcon />
              {t('HOME_TAB_HOME')}
            </button>
            <button
              className={`w-1/2 ${
                activeTab === TABS.ORDER_HISTORY ? 'text-green-500 font-semibold' : 'text-gray-400 font-medium'
              } flex flex-col items-center text-sm leading-5 px-2 py-10`}
              onClick={() => handleTabChange(TABS.ORDER_HISTORY)}
            >
              <UpdateIcon />
              {t('HOME_TAB_ORDERHISTORY')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrder;
