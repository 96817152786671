import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../assets/icon/arrow-left.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icon/credit-card.svg';
import { ReactComponent as MobileCardIcon } from '../../assets/icon/mobile-payment-card.svg';
import Button from '../../components/Button';
import { Dialog, Drawer } from '@mui/material';
import { useOrderContext } from '../../context/OrderContext';
import { OrderItemStatus, PaymentMethod, SHIPPING_FEE } from '../../consts';
import { useTranslation } from 'react-i18next';
import { useMenuContext } from '../../context/MenuContext';
import queryString from 'query-string';
import PAYMENT from './components/Payment';
import { db } from '../../libs/firebase';
import { formatPriceItem } from '../../utils';
import db_config from '../../configs/db_config';
import { ReactComponent as TabilifeLogo } from '../../assets/icon/tabi-life-black.svg';

interface DeliveryInformationProps {}

const DeliveryInformation: React.FC<DeliveryInformationProps> = () => {
  const { t } = useTranslation();
  const { orderDetails, createOrder, updateOrderDetails } = useOrderContext();
  const { setFetchData, listRestaurantData } = useMenuContext();
  const navigate = useNavigate();
  const [openPayment, setOpenPayment] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorFood, setErrorFood] = useState<string>('');
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [formDataPayment, setFormDataPayment] = useState(PaymentMethod.Credit);
  const orderId = sessionStorage.getItem('orderId');
  const seatId = sessionStorage.getItem('seatId');

  const [price, totalPrice, totalItem] = useMemo(() => {
    if (!orderDetails) return [null, 0, 0];
    const totalItem =
      (orderDetails.foods?.reduce((total: any, food: any) => total + parseFloat(food.item_quantity), 0) || 0) +
      (orderDetails.promotion_foods?.reduce((total: any, food: any) => total + parseFloat(food.item_quantity), 0) || 0);
    const totalPrice =
      (orderDetails.foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0) +
      (orderDetails.promotion_foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0);

    const formattedPrice = totalPrice;
    const totalPayment = totalPrice + SHIPPING_FEE;

    return [formattedPrice, totalPayment, totalItem];
  }, [orderDetails]);

  const handleSubmitPayment = async () => {
    if (!orderDetails || !orderId || !seatId) {
      return;
    }

    const restaurantPromotion = listRestaurantData.find((item) => item.id === orderDetails.restaurant_id);

    if (restaurantPromotion && restaurantPromotion.promotion_restaurant_id && orderDetails.promotion_foods?.length > 0) {
      orderDetails.food_order_status = OrderItemStatus.PREPARING;
      orderDetails.food_promotion_order_status = OrderItemStatus.PREPARING;
      orderDetails.promotion_restaurant_id = restaurantPromotion.promotion_restaurant_id;
    }

    try {
      const isErrorOrder = await createOrder(orderDetails, orderId);
      if (!isErrorOrder) {
        const errorFoodItem = sessionStorage.getItem('ErrorFood');
        if (errorFoodItem) {
          setErrorFood(errorFoodItem);
        }
        setOpenError(true);
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const toggleDrawerPayment = (newOpen: boolean) => () => {
    setOpenPayment(newOpen);
  };

  const handlePlaceOrder = async () => {
    orderDetails.payment_method = formDataPayment;
    const isOrderValid = await handleSubmitPayment();
    if (isOrderValid) {
      setOpenPayment(true);
    }
  };

  const handleCloseErrorModal = () => {
    setOpenError(false);
    setFetchData(true);
    if (errorFood) {
      const updated = {
        ...orderDetails,
        foods: [],
        promotion_restaurant_id: '',
        promotion_foods: [],
      };
      if (updated.hasOwnProperty('food_order_status')) {
        delete updated.food_order_status;
      }
      if (updated.hasOwnProperty('food_promotion_order_status')) {
        delete updated.food_promotion_order_status;
      }
      updateOrderDetails(updated);
    }
    sessionStorage.removeItem('ErrorFood');
    const neworderDetailLink = queryString.stringifyUrl({
      url: `/neworder/${orderId}`,
      query: queryParams,
    });
    navigate(neworderDetailLink);
  };

  const handleBackToMenu = () => {
    navigate(-1);
  };

  const handleAddItem = () => {
    const neworderDetailLink = queryString.stringifyUrl({
      url: `/menu/${orderDetails.restaurant_id}`,
      query: queryParams,
    });
    navigate(neworderDetailLink);
  };

  useEffect(() => {
    const checkOrderDetails = async () => {
      if (orderDetails.foods.length === 0) {
        if (!orderId) {
          const homeLink = queryString.stringifyUrl({
            url: `/`,
            query: queryParams,
          });
          navigate(homeLink);
        } else {
          const orderHistory = await db.collection(db_config.DB_TABLE_ALL_ORDERS).doc(orderId).get();
          if (orderHistory.exists) {
            const orderDetailLink = queryString.stringifyUrl({
              url: `/orderdetail/${orderId}`,
              query: queryParams,
            });
            window.location.href = orderDetailLink;
          } else {
            const newOrderDetailLink = queryString.stringifyUrl({
              url: `/neworder/${orderId}`,
              query: queryParams,
            });
            navigate(newOrderDetailLink);
          }
        }
      }
    };

    checkOrderDetails();
  }, [orderDetails, orderId]);

  const totalQuantity = useMemo(() => {
    const promotion_foods = orderDetails?.promotion_foods ?? [];
    const foods = orderDetails?.foods ?? [];
    const allFoods = [...promotion_foods, ...foods];
    return allFoods.reduce((sum: number, food: any) => sum + (food.item_quantity || 0), 0);
  }, [orderDetails]);

  return (
    <div className="w-[auto] min-h-[100vh] bg-black-0">
      <div className="max-w-[672px] min-h-[100vh] mx-auto bg-white">
        <div className="flex w-full justify-center sticky top-0 bg-white gap-2 items-center px-4 border-b">
          <button className="absolute left-3 p-3" onClick={handleBackToMenu}>
            <ArrowLeft />
          </button>
          <p className="text-gray-700 text-base font-medium leading-6 p-3">{t('DELIVERY_TITLE')}</p>
        </div>

        <div className="p-4 flex justify-between border-b">
          <div className="flex flex-col gap-1">
            <p className="text-black-500 text-sm leading-5 font-medium">{t('HOME_SEAT_TITLE')}</p>
            <p className="text-gray-800 font-medium leading-7 text-lg">{orderDetails && orderDetails.seat_number}</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-black-500 text-sm leading-5 font-medium">{t('HOME_ORDERID_TITLE')}</p>
            <p className="text-gray-800 font-medium leading-7 text-lg">{orderId}</p>
          </div>
        </div>

        <div className="flex justify-between py-3 px-4">
          <div className="flex justify-start text-sm leading-5">
            <p className="text-black-500 font-medium border-r border-r-[#EAECF0] pr-2">{t('DELIVERY_ORDER_SUMMARY')}</p>
            <p className="text-black-500 font-medium pl-2">
              {totalQuantity} {t('MENU_CARD_ITEMS')}
            </p>
          </div>
          <div onClick={handleAddItem} className="text-green-700 font-semibold">
            {t('DELIVERY_ORDER_ADD_ITEM')}
          </div>
        </div>

        <div className="">
          {orderDetails &&
            orderDetails.foods.map((item: any, index: any) => (
              <div key={index} className="flex justify-between gap-4 p-4 border-b">
                <div className="flex-1 flex gap-2">
                  <div className="flex flex-col gap-2">
                    <p className="text-black-800 text-xs not-italic font-medium leading-[18px]">{item.item_name}</p>
                    {item.item_customize_settings.length > 0 && (
                      <div className="flex gap-2 pb-1 text-[10px] not-italic font-normal leading-[150%] text-black-500 flex-col">
                        {item.item_customize_settings.map((topping: any, index: any) => (
                          <React.Fragment key={index}>
                            <p>- {topping.name}</p>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    {item.item_note && <p className="text-black-400 text-xs font-normal leading-[18px]">{item.item_note}</p>}
                    <p className="text-xs not-italic font-medium leading-[18px] text-black-800">
                      {t('QUANTITY')}: {item.item_quantity} {t('MENU_CARD_ITEMS')}
                    </p>
                  </div>
                </div>
                <p className="w-fit text-black-500 text-xs font-normal leading-[18px]">¥ {formatPriceItem(item.item_subtotal)}</p>
              </div>
            ))}
          {orderDetails &&
            orderDetails.promotion_foods.map((item: any, index: any) => (
              <div key={index} className="flex justify-between gap-4 p-4 border-b">
                <div className="flex-1 flex gap-2">
                  <div className="flex flex-col gap-2">
                    <p className="text-black-800 text-xs not-italic font-medium leading-[18px]">{item.item_name}</p>
                    {item.item_customize_settings.length > 0 && (
                      <div className="flex gap-2 pb-1 text-[10px] not-italic font-normal leading-[150%] text-black-500 flex-col">
                        {item.item_customize_settings.map((topping: any, index: number) => (
                          <React.Fragment key={index}>
                            <p>- {topping.name}</p>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    {item.item_note && <p className="text-black-400 text-xs font-normal leading-[18px]">{item.item_note}</p>}
                    <p className="text-xs not-italic font-medium leading-[18px] text-black-800">
                      {t('QUANTITY')}: {item.item_quantity} {t('MENU_CARD_ITEMS')}
                    </p>
                  </div>
                </div>
                <p className="w-fit text-black-500 text-xs font-normal leading-[18px]">¥ {formatPriceItem(item.item_subtotal)}</p>
              </div>
            ))}
        </div>

        <div className="pb-[130px] bg-white">
          <div className="py-3 px-4">
            <p className="text-gray-500 text-sm font-medium leading-5">{t('DELIVERY_ORDER_PAYMENT')}</p>
          </div>
          <div className="py-2 px-4">
            <div className="flex justify-between">
              <p className="text-gray-700 text-xs font-normal leading-[18px]">{t('ORDER_PRICE')}</p>
              <p className="text-black-500 text-xs font-normal leading-[18px]">¥ {formatPriceItem(price)}</p>
            </div>
          </div>

          <div className="px-4">
            <div className="flex justify-between border-b py-2">
              <p className="text-gray-700 text-xs font-normal leading-[18px]">{t('ORDER_DELIVERY_FEE')}</p>
              <p className="text-black-500 text-xs font-normal leading-[18px]">¥ {formatPriceItem(SHIPPING_FEE)}</p>
            </div>
          </div>

          <div className="px-4">
            <div className="flex justify-between text-black-800 text-sm font-semibold leading-5 border-b py-4">
              <p className="">{t('ORDER_TOTAL_PAYMENT')}</p>
              <div className="flex gap-[2px]">
                <p>¥ {formatPriceItem(totalPrice)}</p> <p className="text-black-400 font-light">{t('TAX_INCLUDED')}</p>
              </div>
            </div>
          </div>
          <div className="py-3 px-4">
            <p className="text-gray-500 text-sm font-medium leading-5">{t('ORDER_PAYMENT_METHOD')}</p>
          </div>

          <div className="p-4 flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <CreditCardIcon />
              <p className="text-gray-700 text-sm font-medium leading-5">{t(PaymentMethod.Credit)}</p>
            </div>
          </div>
          <div className="px-4 flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <MobileCardIcon />
              <p className="text-gray-700 text-sm font-medium leading-5">{t(PaymentMethod.Mobile)}</p>
            </div>
          </div>

          <div className="w-full flex gap-1 items-center justify-center mt-3">
            <p className="text-gray-700 text-sm font-medium leading-5">{t('POWERED_BY')}</p>
            <TabilifeLogo width={100} height={20} />
          </div>
          <Drawer anchor={'right'} transitionDuration={600} open={openPayment} onClose={toggleDrawerPayment(false)}>
            <PAYMENT toggleDrawer={toggleDrawerPayment} totalPrice={totalPrice} restaurant_id={orderDetails.restaurant_id} />
          </Drawer>
        </div>
        <div className="flex flex-col gap-3 fixed bottom-0 bg-white w-full max-w-[672px] p-4">
          <div className="flex justify-between">
            <label htmlFor="quantity" className="text-sm font-medium leading-5 text-gray-500">
              {totalItem} {t('MENU_CARD_ITEMS')}
            </label>
            <div className="flex gap-1">
              <p className="text-black-800 text-base font-semibold leading-6">¥ {formatPriceItem(totalPrice)}</p>
              <p className="text-black-400 font-light">{t('TAX_INCLUDED')}</p>
            </div>
          </div>
          <Button type="secondary" onClick={handlePlaceOrder}>
            {t('DELIVERY_BUTTON_PLACEORDER')}
          </Button>
        </div>

        <Dialog open={openError} onClose={handleCloseErrorModal} sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}>
          <div className="p-6 flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <p className="text-black-900 text-lg font-semibold leading-7">{errorFood ? `${errorFood} ${t('HAS_BEEN_DISABLE')}` : t('LISTRESTAURANT_MODAL_TITLE')}</p>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DeliveryInformation;
