import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseProdConfig from './firebase_config/firebase.production.config.json';
import firebaseStagConfig from './firebase_config/firebase.staging.config.json';

// Initialize Firebase for production
const firebaseConfig = process.env.REACT_APP_ENV === 'staging' ? firebaseStagConfig : firebaseProdConfig;
const firebaseAppProd = firebase.initializeApp(firebaseConfig, 'production');
export const auth = firebaseAppProd.auth();
export const db = firebaseAppProd.firestore();
export const storage = firebaseAppProd.storage();

// Export Firebase instances
export { firebaseAppProd };
