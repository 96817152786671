import { listRestaurantType } from '../interfaces/types';
import db_config from '../configs/db_config';
import { storage } from '../libs/firebase';

export const isRestaurantOnline = (restaurant: listRestaurantType) => {
  const restaurant_open_state = restaurant.restaurant_open_state === db_config.RESTAURANT_OPEN_STATE_OPENED ? true : false;
  return restaurant_open_state;
};

export const getBrowserLocale = () => {
  const browserLanguage = window.navigator.language;
  if (['ja'].includes(browserLanguage)) {
    return 'ja';
  } else {
    return 'en';
  }
};

export const formatPriceItem = (price: number | string) => {
  const formattedPriceItem = price.toLocaleString('ja-JP');
  return formattedPriceItem;
};

export const getUTCDateStr = () => {
  const today = new Date();
  const yyyy = today.getUTCFullYear().toString();
  const mm = (today.getUTCMonth() + 1).toString().padStart(2, '0');
  const dd = today.getUTCDate().toString().padStart(2, '0');
  return `${yyyy}${mm}${dd}`;
};

export const getTermsAndConditions = async (locale: string, restaurant_id: string) => {
  try {
    const fetchTextFromFile = async (path: string) => {
      try {
        const storageRef = storage.ref(path);
        const url = await storageRef.getDownloadURL();
        const response = await fetch(url);
        return await response.text();
      } catch (error: any) {
        if (error.code === 'storage/object-not-found') {
          return null;
        }
        throw error;
      }
    };

    let file_path = `terms_and_coditions/${restaurant_id}_${locale}.txt`;
    let content = await fetchTextFromFile(file_path);

    if (!content) {
      file_path = `terms_and_coditions/${restaurant_id}.txt`;
      content = await fetchTextFromFile(file_path);
    }

    return content;
  } catch (error) {
    return '';
  }
};
