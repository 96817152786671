import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { db, storage } from '../../libs/firebase';
import { listRestaurantType, CategoriesType, FoodDetailItem, RestaurantWithFoodType, IStadium, MenuContextValue, SeatInformation, Order } from '../../interfaces/types';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { BillStatus, TABS } from '../../consts';
import Papa from 'papaparse';
import db_config from '../../configs/db_config';
import { getBrowserLocale, getTermsAndConditions } from '../../utils';

type ParsedCSVData = string[][];

const initStadiumData = {
  administrators: [],
  enable_sales_count: false,
  seat_number: '',
  fileUrl: '',
  id: '',
  isSeeAll: false,
  isStadiumPromotion: false,
  last_update_date: '',
  latest_id: 0,
  name: '',
  promotion_restaurant_id: '',
  service_name: '',
  stadium_profile_pic: '',
  status: '',
  elepay_secret_key: '',
  elepay_public_key: '',
  location_id: '',
  merchant_id: '',
  riders: [],
  enable_drink_quantity_control: false,
  enable_food_quantity_control: false,
  enable_terms: false,
  max_drink_quantity: 6,
  max_food_quantity: 10,
};

const getStadiumManageFoodDetails = () => {
  return db.collection(db_config.DB_TABLE_ALL_FOOD_DETAILS);
};

const getManageAllCategories = () => {
  return db.collection(db_config.DB_TABLE_ALL_CATEGORIES);
};

const getRestaurantConfig = () => {
  return db.collection(db_config.DB_TABLE_RESTAURANT_CONFIG);
};

const getStadiumConfig = () => {
  return db.collection(db_config.DB_TABLE_STADIUM_MANAGE);
};

const MenuContext = createContext<MenuContextValue>({
  listRestaurantData: [],
  listRestaurantWithFood: [],
  loading: true,
  loadingFoods: true,
  findRestaurantById: () => {},
  setFetchData: () => {},
  setListRestaurantWithFood: () => {},
  updateSeatInformation: async () => {},
  stadiumData: initStadiumData,
  activeTab: TABS.HOME,
  setActiveTab: () => {},
  seatInformation: {
    seat_type: '',
    seat_number: '',
  },
  setStadiumID: () => {},
  setSeatId: () => {},
  restaurantFoodMap: {},
  termsAndConditionsData: '',
});

const MenuProvider = ({ children }: { children: ReactNode }) => {
  const [activeTab, setActiveTab] = useState(TABS.HOME);
  const [loading, setLoading] = useState(true);
  const [loadingFoods, setLoadingFoods] = useState(true);
  const [fetchData, setFetchData] = useState(false);
  const [stadiumID, setStadiumID] = useState<string | null>(() => sessionStorage.getItem('stadiumID'));
  const [seatId, setSeatId] = useState<string | null>(() => sessionStorage.getItem('seatId'));
  const [seatInformation, setSeatInformation] = useState<SeatInformation>({
    seat_type: '',
    seat_number: '',
  });
  const [termsAndConditionsData, setTermsAndConditionsData] = useState<string | null>(null);
  const [listRestaurantData, setListRestaurantData] = useState<listRestaurantType[]>([]);
  const [stadiumData, setStadiumData] = useState<IStadium>(initStadiumData);
  const [listRestaurantWithFood, setListRestaurantWithFood] = useState<RestaurantWithFoodType[]>([]);

  const [restaurantFoodMap, setRestaurantFoodMap] = useState<{ [key: string]: FoodDetailItem[] }>({});

  const getSoldCount = async (restaurantId: string): Promise<number> => {
    const ordersCollectionRef = collection(db, db_config.DB_TABLE_ALL_ORDERS);

    const normalOrdersQuery = query(ordersCollectionRef, where('restaurant_id', '==', restaurantId), where('bill_status', '==', BillStatus.CLEARED));

    const promotionOrdersQuery = query(ordersCollectionRef, where('promotion_restaurant_id', '==', restaurantId), where('bill_status', '==', BillStatus.CLEARED));

    const normalOrdersSnapshot = await getDocs(normalOrdersQuery);
    const promotionOrdersSnapshot = await getDocs(promotionOrdersQuery);

    const normalOrdersCount = normalOrdersSnapshot.docs.filter((doc) => !doc.data().is_damaged).length;
    const promotionOrdersCount = promotionOrdersSnapshot.docs.filter((doc) => !doc.data().is_damaged).length;

    return normalOrdersCount + promotionOrdersCount;
  };

  const fetchRestaurantData = useCallback(() => {
    try {
      setLoading(true);
      let restaurantConfigData: IStadium;

      const unsubscribeStadium = stadiumID
        ? getStadiumConfig()
            .doc(stadiumID)
            .onSnapshot(async (restaurantConfig) => {
              const result = restaurantConfig.exists ? restaurantConfig.data() : null;
              restaurantConfigData = result as IStadium;
              setStadiumData({ ...restaurantConfigData, id: restaurantConfig.id });
              const enable_terms = restaurantConfigData?.enable_terms ?? false;
              if (enable_terms) {
                const locale = getBrowserLocale();
                const terms_and_coditions = await getTermsAndConditions(locale, restaurantConfig.id);
                setTermsAndConditionsData(terms_and_coditions ?? null);
              }
            })
        : null;

      const unsubscribeCategories = getManageAllCategories().onSnapshot((querycategories) => {
        const categoryDocs: CategoriesType[] = querycategories.docs.map((doc) => ({
          id: doc.id,
          category_show: doc.data().category_show,
          foooIds: doc.data().foodIds,
          order: doc.data().order,
          restaurantId: doc.data().restaurantId,
          title: doc.data().title,
        }));

        const unsubscribeRestaurant = getRestaurantConfig()
          .where('stadium_id', '==', stadiumID)
          .where('status', '==', 'active')
          .onSnapshot(async (queryRestaurantConfig) => {
            const listRestaurant = await Promise.all(
              queryRestaurantConfig.docs.map(async (doc) => {
                const data = doc.data();
                const soldCount = await getSoldCount(doc.id);

                const restaurant: listRestaurantType = {
                  id: doc.id,
                  name: data.name,
                  region: data.region,
                  location_id: data.location_id ?? '',
                  merchant_id: data.merchant_id ?? '',
                  restaurant_profile_pic: data.restaurant_profile_pic,
                  categories: categoryDocs.filter((category) => category.category_show === 1 && category.restaurantId === doc.id),
                  opening_schedule: data.opening_schedule,
                  isOnline: data.isOnline,
                  restaurant_open_id: data.restaurant_open_id,
                  restaurant_open_state: data.restaurant_open_state,
                  isStadiumPromotion: restaurantConfigData?.isStadiumPromotion ?? false,
                  promotion_restaurant_id: restaurantConfigData?.promotion_restaurant_id ?? '',
                  sold: soldCount,
                };

                return restaurant;
              })
            );

            listRestaurant.sort((a, b) => b.sold - a.sold);
            setListRestaurantData(listRestaurant);
            setLoading(false);
          });

        return () => {
          unsubscribeRestaurant && unsubscribeRestaurant();
        };
      });

      return () => {
        unsubscribeStadium && unsubscribeStadium();
        unsubscribeCategories && unsubscribeCategories();
      };
    } catch (error) {
      setLoading(false);
    }
  }, [stadiumID]);

  const fetchFoodsData = useCallback(() => {
    setLoadingFoods(true);
    if (listRestaurantData.length > 0) {
      const restaurantFoodMap: Record<string, FoodDetailItem[]> = {};
      const addedFoodIds: Record<string, boolean> = {};

      listRestaurantData.forEach((restaurant) => {
        const categoryIds = restaurant.categories.map((categoryDoc) => categoryDoc.id);
        const foodChunks = chunkArray(categoryIds, 20);

        foodChunks.forEach((chunk) => {
          getStadiumManageFoodDetails()
            .where('restaurantId', '==', restaurant.id)
            .where('categories', 'array-contains-any', chunk)
            .orderBy('create_time', 'desc')
            .onSnapshot(
              (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                  const foodData = change.doc.data();
                  const foodDetail: FoodDetailItem = {
                    enabled: foodData.enabled,
                    enabled_note: foodData.enabled_note,
                    isEnableStockCount: foodData.isEnableStockCount,
                    description: foodData.description,
                    food_show_status: foodData.food_show_status,
                    unit_price: foodData.unit_price,
                    restaurantId: foodData.restaurantId,
                    customize_group_settings: foodData.customize_group_settings,
                    id: foodData.id,
                    price: foodData.price,
                    image_url: foodData.image_url,
                    name: foodData.name,
                    food_id: foodData.food_id,
                    categories: foodData.categories,
                    sku: foodData.sku,
                    tax_rate: foodData.tax_rate,
                    food_type: foodData.food_type,
                  };

                  switch (change.type) {
                    case 'added':
                      if (!addedFoodIds[foodDetail.id]) {
                        if (!restaurantFoodMap[foodDetail.restaurantId]) {
                          restaurantFoodMap[foodDetail.restaurantId] = [];
                        }
                        restaurantFoodMap[foodDetail.restaurantId].push(foodDetail);
                        addedFoodIds[foodDetail.id] = true;
                      }
                      break;
                    case 'modified':
                      if (restaurantFoodMap[foodDetail.restaurantId]) {
                        restaurantFoodMap[foodDetail.restaurantId] = restaurantFoodMap[foodDetail.restaurantId].map((item) =>
                          item.id === foodDetail.id ? foodDetail : item
                        );
                      }
                      break;
                    case 'removed':
                      if (restaurantFoodMap[foodDetail.restaurantId]) {
                        restaurantFoodMap[foodDetail.restaurantId] = restaurantFoodMap[foodDetail.restaurantId].filter((item) => item.id !== foodDetail.id);
                        delete addedFoodIds[foodDetail.id];
                      }
                      break;
                    default:
                      break;
                  }
                });
                setRestaurantFoodMap({ ...restaurantFoodMap });
              },
              (error) => {
                console.error('Error fetching food snapshot:', error);
              }
            );
        });
      });
    }
  }, [listRestaurantData]);

  function chunkArray(categories: any, size: number) {
    const chunkedArr = [];
    let index = 0;
    while (index < categories.length) {
      chunkedArr.push(categories.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  }

  const getSoldCountFood = async (restaurantId: string, id: string): Promise<number> => {
    const collectionRef = collection(db, db_config.DB_TABLE_ALL_ORDERS);
    let totalCount = 0;

    const fetchFoodCount = async (queryCondition: any) => {
      const snapshot = await getDocs(queryCondition);
      snapshot.forEach((doc) => {
        const data = doc.data() as Order;

        if (!data.is_damaged) {
          const foods = data.foods || [];
          const promotionFoods = data.promotion_foods || [];

          totalCount += foods.filter((food: any) => food.item_food_id === id).reduce((sum: number, food: any) => sum + (food.item_quantity || 0), 0);

          totalCount += promotionFoods.filter((food: any) => food.item_food_id === id).reduce((sum: number, food: any) => sum + (food.item_quantity || 0), 0);
        }
      });
    };

    const normalOrderQuery = query(collectionRef, where('restaurant_id', '==', restaurantId), where('bill_status', '==', BillStatus.CLEARED));
    await fetchFoodCount(normalOrderQuery);

    const promotionOrderQuery = query(collectionRef, where('promotion_restaurant_id', '==', restaurantId), where('bill_status', '==', BillStatus.CLEARED));
    await fetchFoodCount(promotionOrderQuery);

    return totalCount;
  };

  const mergeRestaurantData = async () => {
    const updatedListRestaurantData = await Promise.all(
      listRestaurantData.map(async (restaurant) => {
        if (restaurant.id in restaurantFoodMap) {
          const filteredFoods = restaurantFoodMap[restaurant.id].filter((food) =>
            food.categories.some((foodCategory) => restaurant.categories.some((restaurantCategory) => restaurantCategory.id === foodCategory))
          );

          const updatedFoodDetails = await Promise.all(
            filteredFoods.map(async (food) => {
              if (!food.sold) {
                try {
                  const soldCount = await getSoldCountFood(food.restaurantId, food.id);
                  return { ...food, sold: soldCount };
                } catch (error) {
                  console.error('Error fetching sold count:', error);
                  return food;
                }
              }
              return food;
            })
          );

          return { ...restaurant, food_details: updatedFoodDetails };
        } else {
          return { ...restaurant, food_details: [] };
        }
      })
    );

    setListRestaurantWithFood(updatedListRestaurantData);
    setLoadingFoods(false);
  };

  const findRestaurantById = (id: string): RestaurantWithFoodType | undefined => {
    const restaurant = listRestaurantWithFood.find((restaurant) => restaurant.id === id);
    return restaurant ? restaurant : undefined;
  };

  const parseCSV = (file: string): Promise<ParsedCSVData> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          resolve(results.data as ParsedCSVData);
        },
        header: false,
        error: (error: any) => {
          reject(error);
        },
      });
    });
  };

  const fetchCSVFromStorage = async (stadiumId: string): Promise<ParsedCSVData> => {
    try {
      const storageRef = storage.ref(`stadium/${stadiumId}`);
      const url = await storageRef.getDownloadURL();

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.text();
      return await parseCSV(data);
    } catch (error) {
      console.error('Error fetching or parsing CSV:', error);
      return [];
    }
  };

  const findRowByColumnValue = (data: ParsedCSVData, columnIndex: number, value: string): string[] | null => {
    for (const row of data) {
      if (row[columnIndex] === value) {
        return row;
      }
    }
    return null;
  };

  const getRowById = async (stadiumId: string, searchValue: string): Promise<string[] | null> => {
    try {
      const data = await fetchCSVFromStorage(stadiumId);

      const columnIndex = data[0].length - 1;
      return findRowByColumnValue(data, columnIndex, searchValue);
    } catch (error) {
      return null;
    }
  };

  const updateSeatInformation = async () => {
    const information = await getRowById(stadiumID ?? '', seatId ?? '');
    setSeatInformation({
      seat_type: information?.[1] ?? '',
      seat_number: `${information?.[2] ?? ''}: ${information?.[3] ?? ''}`,
    });
  };

  useEffect(() => {
    const fetchSeatInformation = async () => {
      if (seatId && seatId !== '') {
        await updateSeatInformation();
      }
    };

    fetchSeatInformation();
  }, [seatId]);

  useEffect(() => {
    fetchRestaurantData();
  }, [stadiumID, fetchData]);

  useEffect(() => {
    fetchFoodsData();
  }, [listRestaurantData]);

  useEffect(() => {
    const fetchData = async () => {
      await mergeRestaurantData();
    };

    fetchData();
  }, [restaurantFoodMap]);

  return (
    <MenuContext.Provider
      value={{
        loading,
        loadingFoods,
        listRestaurantData,
        listRestaurantWithFood,
        findRestaurantById,
        setFetchData,
        setListRestaurantWithFood,
        updateSeatInformation,
        stadiumData,
        activeTab,
        setActiveTab,
        seatInformation,
        setStadiumID,
        setSeatId,
        restaurantFoodMap,
        termsAndConditionsData,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

const useMenuContext = () => {
  const context = useContext(MenuContext);
  return context;
};

export { MenuProvider, useMenuContext };
