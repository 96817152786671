import React from 'react';
import { FoodItem, ICustomerSettings } from '../../../interfaces/types';

interface FoodItemProps {
  item: FoodItem;
  t: (key: string) => string;
  calculateSubtotal: (item: FoodItem, calculateSubtotal?: boolean) => string;
}

const FoodDetails: React.FC<FoodItemProps> = ({ item, t, calculateSubtotal }) => {
  const remainingQuantity = item.item_quantity - (item.item_deleted_quantity || 0);
  const shouldShowTwoItems = item.item_deleted_quantity !== undefined && item.item_deleted_quantity > 0;

  return (
    <>
      <div className={`flex justify-between gap-4 p-4 border-b ${shouldShowTwoItems ? 'line-through' : ''}`}>
        <div className="flex-1 flex gap-2">
          <div className="flex flex-col gap-2">
            <p className="text-black-800 text-xs not-italic font-medium leading-[18px]">{item.item_name}</p>
            {item.item_customize_settings.length > 0 && (
              <div className="flex gap-2 pb-1 text-[10px] not-italic font-normal leading-[150%] text-black-500 flex-col">
                {item.item_customize_settings.map((topping: ICustomerSettings, index: number) => (
                  <p key={index}>- {topping.name}</p>
                ))}
              </div>
            )}
            {item.item_note && <p className="text-black-400 text-xs font-normal leading-[18px]">{item.item_note}</p>}
            <p className="text-xs not-italic font-medium leading-[18px] text-black-800">
              {t('QUANTITY')}: {item.item_quantity} {t('MENU_CARD_ITEMS')}
            </p>
          </div>
        </div>
        <p className="w-fit text-black-500 text-xs font-normal leading-[18px]">¥ {(parseFloat(calculateSubtotal(item)) || 0)?.toLocaleString('ja-JP')}</p>
      </div>

      {shouldShowTwoItems && (
        <div className="flex justify-between gap-4 p-4 border-b">
          <div className="flex-1 flex gap-2">
            <div className="flex flex-col gap-2">
              <p className="text-black-800 text-xs not-italic font-medium leading-[18px]">{item.item_name}</p>
              {item.item_customize_settings.length > 0 && (
                <div className="flex gap-2 pb-1 text-[10px] not-italic font-normal leading-[150%] text-black-500 flex-col">
                  {item.item_customize_settings.map((topping: any, index: any) => (
                    <p key={index}>- {topping.name}</p>
                  ))}
                </div>
              )}
              {item.item_note && <p className="text-black-400 text-xs font-normal leading-[18px]">{item.item_note}</p>}
              <p className="text-xs not-italic font-medium leading-[18px] text-black-800">
                {t('QUANTITY')}: {remainingQuantity} {t('MENU_CARD_ITEMS')}
              </p>
            </div>
          </div>
          <p className="w-fit text-black-500 text-xs font-normal leading-[18px]">¥ {(parseFloat(calculateSubtotal(item, true)) || 0)?.toLocaleString('ja-JP')}</p>
        </div>
      )}
    </>
  );
};

export default FoodDetails;
