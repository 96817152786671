import { useTranslation } from 'react-i18next';
import { BillStatus, DamagedItemStatus, OrderItemStatus, SHIPPING_FEE } from '../../../../../consts';
import { FoodItem, listRestaurantType, Order } from '../../../../../interfaces/types';
import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { formatPriceItem, getBrowserLocale } from '../../../../../utils';
import { Drawer, Dialog } from '@mui/material';
import PAYMENT from '../../../../DeliveryInformation/components/Payment';
import useCreateOrder from '../../../../../hook/useCreateOrder';

const STATUS_STYLES = {
  PREPARING: { color: 'text-[#B42318]', bgColor: 'bg-[#FEF3F2]' },
  READY_FOR_PICK_UP: { color: 'text-[#B54708]', bgColor: 'bg-[#FFFAEB]' },
  DELIVERING: { color: 'text-[#026AA2]', bgColor: 'bg-[#F0F9FF]' },
  DELIVERED: { color: 'text-[#027A48]', bgColor: 'bg-[#ECFDF3]' },
  CANCELLED: { color: 'text-[#B42318]', bgColor: 'bg-[#FEF3F2]' },
};

export const STATUS_COLOR_MAP = {
  [OrderItemStatus.PREPARING]: { ...STATUS_STYLES.PREPARING, label: 'PREPARING' },
  [OrderItemStatus.DAMAGED_PREPARING]: { ...STATUS_STYLES.DELIVERING, label: 'DELIVERING' },
  [OrderItemStatus.READY_FOR_PICK_UP]: { ...STATUS_STYLES.READY_FOR_PICK_UP, label: 'READY_FOR_PICK_UP' },
  [OrderItemStatus.DAMAGED_READY_FOR_PICK_UP]: { ...STATUS_STYLES.DELIVERING, label: 'DELIVERING' },
  [OrderItemStatus.DELIVERING]: { ...STATUS_STYLES.DELIVERING, label: 'DELIVERING' },
  [OrderItemStatus.DAMAGED_DELIVERING]: { ...STATUS_STYLES.DELIVERING, label: 'DELIVERING' },
  [OrderItemStatus.DELIVERED]: { ...STATUS_STYLES.DELIVERED, label: 'DELIVERED' },
  [OrderItemStatus.CANCELLED]: { ...STATUS_STYLES.DELIVERED, label: 'REFUNDED' },
};

const OrderItem: React.FC<{ order: Order; restaurant?: listRestaurantType; onShowDetail: (order: Order) => void; onReorder: (order: Order) => void }> = ({
  order,
  restaurant,
  onReorder,
  onShowDetail,
}) => {
  const { t } = useTranslation();
  const [openPayment, setOpenPayment] = useState(false);
  const { checkOrder } = useCreateOrder();
  const [openError, setOpenError] = useState(false);
  const [errorFood, setErrorFood] = useState<string>('');

  const handlePlaceOrder = async () => {
    const isErrorOrder = await checkOrder(order as any);
    if (isErrorOrder) {
      setOpenPayment(true);
    } else {
      const errorFoodItem = sessionStorage.getItem('ErrorFood');
      if (errorFoodItem) {
        setErrorFood(errorFoodItem);
      }
      setOpenError(true);
    }
  };

  const toggleDrawerPayment = (newOpen: boolean) => () => {
    setOpenPayment(newOpen);
  };

  const handleCloseErrorModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    sessionStorage.removeItem('ErrorFood');
    setOpenError(false);
  };

  const formatJapaneseDate = (date: Date): string => {
    const year = format(date, 'yyyy', { locale: ja });
    const month = format(date, 'MM', { locale: ja });
    const day = format(date, 'dd', { locale: ja });
    const time = format(date, 'HH:mm', { locale: ja });
    return `${year}年${month}月${day}日, ${time}`;
  };

  const [price, formattedTime, totalItem] = useMemo<[number, string, number]>(() => {
    if (!order) return [0, '', 0];

    const totalItem =
      (order.foods?.reduce(
        (total: number, food: FoodItem) => total + (food.item_deleted_quantity !== undefined ? food.item_quantity - food.item_deleted_quantity : food.item_quantity),
        0
      ) || 0) +
      (order.promotion_foods?.reduce(
        (total: number, food: FoodItem) => total + (food.item_deleted_quantity !== undefined ? food.item_quantity - food.item_deleted_quantity : food.item_quantity),
        0
      ) || 0);

    const shippingFee = order.order_status === OrderItemStatus.CANCELLED ? 0 : totalItem === 0 ? 0 : SHIPPING_FEE;

    const calculateSubtotal = (foods: FoodItem[]) => {
      return (
        foods?.reduce((total: number, food: FoodItem) => {
          const actualQuantity = food.item_deleted_quantity !== undefined ? food.item_quantity - food.item_deleted_quantity : food.item_quantity;
          return total + actualQuantity * food.item_price;
        }, 0) || 0
      );
    };

    const totalPrice = calculateSubtotal(order.foods) + calculateSubtotal(order.promotion_foods);
    const totalPayment = totalPrice + shippingFee;
    const formattedPrice = totalPayment;

    const date = new Date(order.order_create_time.seconds * 1000);
    const locale = getBrowserLocale();
    const formattedTime = locale === 'ja' ? formatJapaneseDate(date) : format(date, 'dd/MM/yyyy, HH:mm');

    return [formattedPrice, formattedTime || '', totalItem];
  }, [order]);

  const totalValidItems = useMemo(() => {
    const totalItemQuantity = order.foods.reduce((total, item) => total + item.item_quantity, 0);
    const totalDeletedQuantity = order.foods.reduce((total, item) => total + (item.item_deleted_quantity ?? 0), 0);

    const isPartiallyCancelled = totalItemQuantity > totalDeletedQuantity && totalDeletedQuantity > 0 && order.order_status === OrderItemStatus.DELIVERED;

    return isPartiallyCancelled ? { ...STATUS_STYLES.DELIVERED, label: 'PARTIALLY_CANCELED_REFUNDED' } : STATUS_COLOR_MAP[order.order_status];
  }, [order.foods, order.order_status]);

  return (
    <div
      aria-hidden
      onClick={(e) => {
        onShowDetail(order);
      }}
      className="p-4 flex flex-col gap-3 bg-white border-b border-[#EAECF0]"
    >
      <div className="flex items-center justify-between">
        <p className="text-[#344054] w-fit text-sm capitalize font-medium leading-5 rounded-2xl bg-[#F2F4F7] px-2 py-[2px]">{order.id}</p>

        <p className={`w-fit text-xs capitalize font-medium leading-[18px] rounded-2xl px-2 py-[2px] ${totalValidItems.bgColor} ${totalValidItems.color}`}>
          {t(`${totalValidItems.label}`)}
        </p>
      </div>
      <div className="flex items-center gap-3">
        <img src={restaurant?.restaurant_profile_pic} alt={restaurant?.name} className="w-[100px] h-[100px] shrink-0 rounded-lg" />
        <div className="flex flex-col gap-1">
          <p className="text-[#1D2939] text-base leading-6 font-medium">{restaurant?.name}</p>
          <p className="text-black-500 text-xs leading-[18px] font-light">{formattedTime}</p>
          <p className="text-[#475467] text-xs leading-[18px] font-normal line-clamp-2">
            {order.foods.map((item) => item.item_name).join('; ')}
            {order.promotion_foods && order.promotion_foods.length > 0 && (
              <>
                {'; '}
                {order.promotion_foods.map((item) => item.item_name).join('; ')}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="border-solid border-[2px] border-[#EAECF0]" />
      <div className="flex items-center gap-3">
        <div className="flex-1 flex items-center gap-2">
          <p className="text-[#1D2939] text-[18px] leading-7 font-medium">¥ {formatPriceItem(price)}</p>
          <div className=" border-l border-[#EAECF0] h-4" />
          <p className="text-[#667085] text-sm leading-5 font-normal">
            {totalItem} {t('MENU_CARD_ITEMS')}
          </p>
        </div>
        {order.bill_status === BillStatus.CLEARED ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onReorder(order);
            }}
            className="border border-[#D0D5DD] px-[14px] py-2 rounded-lg text-sm font-semibold"
          >
            {t('RE_ORDER')}
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePlaceOrder();
            }}
            className="border border-[#D0D5DD] px-[14px] py-2 rounded-lg text-sm font-semibold bg-primary-green text-white"
          >
            {t('COMPLETE_PAYMENT')}
          </button>
        )}
        <Drawer anchor={'right'} transitionDuration={600} open={openPayment} onClose={toggleDrawerPayment(false)}>
          <PAYMENT toggleDrawer={toggleDrawerPayment} totalPrice={price} orderId={order.id} restaurant_id={order.restaurant_id} />
        </Drawer>
      </div>
      <Dialog
        open={openError}
        onClose={(e: React.MouseEvent<HTMLDivElement>) => {
          handleCloseErrorModal(e);
        }}
        sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
      >
        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()} className="p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <p className="text-black-900 text-lg font-semibold leading-7">{errorFood ? `${errorFood} ${t('HAS_BEEN_DISABLE')}` : t('LISTRESTAURANT_MODAL_TITLE')}</p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OrderItem;
