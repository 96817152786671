export enum TABS {
  HOME = 'home',
  ORDER_HISTORY = 'orderHistory',
}

export enum TopicName {
  RIDER = 'RIDER',
  MANAGE = 'MANAGE',
}

export enum OrderItemStatus {
  PREPARING = 'PREPARING',
  CANCELLED = 'CANCELLED',
  DELIVERING = 'DELIVERING',
  READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
  DELIVERED = 'DELIVERED',
  DAMAGED_PREPARING = 'DAMAGED_PREPARING',
  DAMAGED_READY_FOR_PICK_UP = 'DAMAGED_READY_FOR_PICK_UP',
  DAMAGED_DELIVERING = 'DAMAGED_DELIVERING',
}

export enum BillStatus {
  NOT_CLEARED = 'NOT_CLEARED',
  BILL_CANCELLED_AS_SHOP_CLOSED = 'BILL_CANCELLED_AS_SHOP_CLOSED',
  CLEARED = 'CLEARED',
}

export enum DamagedItemStatus {
  DAMAGED_CREATED = 'DAMAGED_CREATED',
  DAMAGED_PREPARING = 'DAMAGED_PREPARING',
  DAMAGED_READY_FOR_PICK_UP = 'DAMAGED_READY_FOR_PICK_UP',
  DAMAGED_DELIVERING = 'DAMAGED_DELIVERING',
  DAMAGED_DELIVERED = 'DAMAGED_DELIVERED',
  DAMAGED_CANCELLED = 'DAMAGED_CANCELLED',
}

export enum PaymentStatus {
  CAPTURED = 'captured',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export enum PaymentMethod {
  Credit = 'CREDIT_CARD',
  Mobile = 'MOBILE_PAYMENT',
}

const LANGUAGES = {
  JAPAN: 'ja',
  ENGLISH: 'en',
};

const SITES = {
  CMS: 'cms',
  CLIENT: 'client',
};

export { SITES, LANGUAGES };

export const SHIPPING_FEE = 770;
