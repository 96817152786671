import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import { useNavigate } from 'react-router-dom';
import { Dialog, Drawer } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { RestaurantWithFoodType, FoodDetailItem, FoodItem } from '../../interfaces/types';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { ReactComponent as LocationIcon } from '../../../src/assets/icon/location.svg';
import { ReactComponent as ArrowLeft } from '../../../src/assets/icon/arrow-left.svg';
import { ReactComponent as NoteIcon } from '../../../src/assets/icon/note.svg';
import { ReactComponent as ShoppingIcon } from '../../../src/assets/icon/trash.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ClearIcon from '@mui/icons-material/Clear';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import ItemCounter from '../Menu/components/ItemCouter';
import { useOrderContext } from '../../context/OrderContext';
import CustomizeDish from '../Menu/components/CustomizeDish';
import Button from '../../components/Button';
import AddPromotion from './components/AddPromotion';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Loader from './components/Loader';
import { formatPriceItem, isRestaurantOnline } from '../../utils';
import { ReactComponent as TabilifeLogo } from '../../assets/icon/tabi-life-black.svg';

const Menu: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [opacityBg, setOpacityBg] = useState(1);
  const [opacityAfter, setOpacityAfter] = useState(0);
  const [restaurantById, setRestaurantById] = useState<RestaurantWithFoodType>();
  const [foods, setFoods] = useState<FoodDetailItem[]>();
  const { findRestaurantById, loading, loadingFoods, listRestaurantData, setListRestaurantWithFood, listRestaurantWithFood, stadiumData, seatInformation } =
    useMenuContext();
  const { updateOrderDetails, orderDetails } = useOrderContext();
  const { id } = params;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const itemsRef = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const activeCategoryRef = useRef<string | null>(null);
  const [categoryCounts, setCategoryCounts] = useState<{ [key: string]: number }>({});
  const [activeFood, setActiveFood] = useState<{ [key: string]: number }>({});
  const [customDishData, setCustomDishData] = useState<any>(null);
  const [openCustomDish, setOpenCustomDish] = useState(false);
  const [openPromotionPopup, setOpenPromotionPopup] = useState(false);
  const [formDataDish, setFormDataDish] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const orderId = sessionStorage.getItem('orderId');
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  let FoodTotalCount = orderDetails.foods.reduce((total, food) => total + food.item_quantity, 0);
  let TotalOrderPrice = Number(orderDetails.foods.reduce((total, food) => total + food.item_quantity * food.item_price, 0).toFixed(2));
  const cartAnimation = useSpring({
    transform: showCart ? 'translateY(0%)' : 'translateY(100%)',
    opacity: showCart ? 1 : 0,
  });
  const navigationRef = useRef<HTMLDivElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState({ value: false, type: '' });
  const handleCloseDialog = () => {
    setOpenDialog({ value: false, type: '' });
  };
  const enable_drink_quantity_control = stadiumData.enable_drink_quantity_control;
  const enable_food_quantity_control = stadiumData.enable_food_quantity_control;
  const max_drink_quantity = stadiumData.max_drink_quantity;
  const max_food_quantity = stadiumData.max_food_quantity;

  const handleBackTolistRestaurant = () => {
    if (FoodTotalCount === 0) {
      const neworderDetailLink = queryString.stringifyUrl({
        url: `/neworder/${orderId}`,
        query: queryParams,
      });
      navigate(neworderDetailLink);
    } else {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleNavigateOrder = () => {
    removeCart();
    const neworderDetailLink = queryString.stringifyUrl({
      url: `/neworder/${orderId}`,
      query: queryParams,
    });
    navigate(neworderDetailLink);
  };

  const removeCart = () => {
    orderDetails.foods = [] as FoodItem[];
    orderDetails.promotion_foods = [] as FoodItem[];
    FoodTotalCount = 0;
    TotalOrderPrice = 0;
    updateOrderDetails(orderDetails);
    setShowCart(false);
  };

  const editItemOrder = (item: FoodDetailItem, categoryId: string) => {
    const matchingFood = orderDetails.foods.find((food) => food.item_food_id === item.id && food.item_category_id === categoryId);

    if (matchingFood) {
      const newData = {
        ...item,
        food: matchingFood,
      };
      setCustomDishData(newData);
      setOpenCustomDish(true);
    }
  };

  const findQuantityInOrderDetail = useMemo(() => {
    return (itemId: string, categoryId: string) => {
      const foundItem = orderDetails.foods.find((food) => food.item_food_id === itemId && food.item_category_id === categoryId);

      return foundItem ? foundItem.item_quantity : 0;
    };
  }, [orderDetails]);

  const handleSetCount = (newCount: number, itemId: string, categoryId: string) => {
    if (newCount === 0) {
      const updatedOrderDetails = orderDetails.foods.filter((food: FoodItem) => !(food.item_food_id === itemId && food.item_category_id === categoryId));
      updateOrderDetails({ ...orderDetails, foods: updatedOrderDetails });
    } else {
      const updatedOrderDetails = orderDetails.foods.map((food: FoodItem) => {
        if (food.item_food_id === itemId && food.item_category_id === categoryId) {
          return { ...food, item_quantity: newCount, item_subtotal: newCount * food.item_price };
        }
        return food;
      });
      updateOrderDetails({ ...orderDetails, foods: updatedOrderDetails });
    }
  };

  const toggleCart = (item: FoodDetailItem, categoryID: string) => {
    const newITem = { ...item, categories: [categoryID] };
    setCustomDishData(newITem);
    setOpenCustomDish(true);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenCustomDish(newOpen);
  };

  const toggleDrawerOpenPromotionPopup = (newOpen: boolean) => () => {
    setOpenPromotionPopup(newOpen);
  };

  const calculateFoodDrinkCount = (foodsList: any[], excludedId?: string, additionalItem?: any) => {
    let foodCount = 0;
    let drinkCount = 0;

    if (!foods || foods.length === 0) {
      return { foodCount, drinkCount };
    }

    const filteredFoods = excludedId ? foodsList.filter((item) => item.item_food_id !== excludedId) : foodsList;

    filteredFoods.forEach((item) => {
      const foodItem = foods.find((f) => f.id === item.item_food_id);
      const foodType = foodItem?.food_type || 'food';

      if (foodType === 'drink') {
        drinkCount += item.item_quantity;
      } else {
        foodCount += item.item_quantity;
      }
    });

    if (additionalItem) {
      const newFoodItem = foods.find((f) => f.id === additionalItem.id);
      const newFoodType = newFoodItem?.food_type || 'food';

      if (newFoodType === 'drink') {
        drinkCount += additionalItem.quantity;
      } else {
        foodCount += additionalItem.quantity;
      }
    }

    return { foodCount, drinkCount };
  };

  const checkFoodDrinkLimit = (foodCount: number | null, drinkCount: number | null) => {
    if (enable_food_quantity_control && foodCount !== null && foodCount > max_food_quantity) {
      setOpenDialog({ value: true, type: 'food' });
      return true;
    }

    if (enable_drink_quantity_control && drinkCount !== null && drinkCount > max_drink_quantity) {
      setOpenDialog({ value: true, type: 'drink' });
      return true;
    }

    return false;
  };

  const handleFormSubmitDish = (data: any) => {
    const foodItem = foods?.find((f) => f.id === data.id);
    const foodType = foodItem?.food_type || 'food';

    const { foodCount, drinkCount } = calculateFoodDrinkCount(orderDetails.foods, data.id, data);

    if (foodType === 'drink') {
      if (checkFoodDrinkLimit(null, drinkCount)) return;
    } else {
      if (checkFoodDrinkLimit(foodCount, null)) return;
    }
    setFormDataDish(data);
    setOpenCustomDish(false);
  };

  const handleDelivery = () => {
    if (orderDetails.foods.length === 0) {
      return;
    }
    updateOrderDetails(orderDetails);
    const deliveryDetailLink = queryString.stringifyUrl({
      url: `/delivery`,
      query: queryParams,
    });
    navigate(deliveryDetailLink);
  };

  const addToCart = (item: any) => {
    const existingItemIndex = orderDetails.foods.findIndex((existingItem) => {
      return existingItem.item_category_id === item.category_id && existingItem.item_food_id === item.id;
    });

    if (existingItemIndex !== -1) {
      const updatedFoods = [...orderDetails.foods];
      updatedFoods[existingItemIndex] = {
        item_category_id: item.category_id,
        item_food_id: item.id,
        item_price: item.price,
        item_name: item.name,
        item_note: item.note,
        item_quantity: item.quantity,
        item_subtotal: item.total_price,
        sku: item.sku,
        tax_rate: item.tax_rate,
        item_customize_settings: item.chooseToppings,
      };
      updateOrderDetails({ ...orderDetails, foods: updatedFoods });
    } else {
      const newItemData: FoodItem = {
        item_category_id: item.category_id,
        item_food_id: item.id,
        item_price: item.price,
        item_name: item.name,
        item_note: item.note,
        item_quantity: item.quantity,
        item_subtotal: item.total_price,
        sku: item.sku,
        tax_rate: item.tax_rate,
        item_customize_settings: item.chooseToppings,
      };
      updateOrderDetails({ ...orderDetails, foods: [...orderDetails.foods, newItemData] });
    }
  };

  useEffect(() => {
    if (restaurantById && restaurantById.categories) {
      const listFood = restaurantById.food_details.filter((food) => food.enabled);
      setFoods(listFood);
      const counts: { [key: string]: number } = {};

      restaurantById.categories
        .filter((category) => category.category_show === 1)
        .sort((a, b) => a.order - b.order)
        .forEach((category) => {
          counts[category.title] = 0;
          restaurantById.food_details.forEach((food) => {
            if (food.categories.includes(category.id) && food.enabled === true) {
              counts[category.title]++;
            }
          });
        });

      const firstCategory = restaurantById?.categories.filter((category: any) => counts[category?.title] > 0).sort((a: any, b: any) => a.order - b.order)[0];
      if (firstCategory) {
        activeCategoryRef.current = firstCategory?.title;
      }
      setCategoryCounts(counts);
    }
  }, [restaurantById]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollPosition = window.scrollY;
      let newActiveCategory = null;

      if (!restaurantById?.categories?.length) return;

      const sortedCategories = restaurantById.categories.filter((category) => categoryCounts[category.title] > 0).sort((a, b) => a.order - b.order);

      sortedCategories.forEach((category, index) => {
        const categoryElement = itemsRef.current[category.title];
        if (!categoryElement) return;

        const categoryTop = categoryElement.offsetTop + 200;
        const categoryBottom = categoryTop + categoryElement.clientHeight;

        const isFirstCategory = index === 0 && scrollPosition <= 216;
        const isInCategoryRange = scrollPosition >= categoryTop && scrollPosition < categoryBottom;

        if (isFirstCategory || isInCategoryRange) {
          newActiveCategory = category.title;
        }
      });

      if (newActiveCategory && newActiveCategory !== activeCategoryRef.current) {
        activeCategoryRef.current = newActiveCategory;
        const buttonElement = document.querySelector(`[data-category="${newActiveCategory}"]`) as HTMLButtonElement;
        if (buttonElement && navigationRef.current) {
          scrollNavigationToButton(buttonElement, navigationRef.current);
        }
      }

      setOpacityBg(1 - scrollTop / 280);
      setOpacityAfter(scrollTop / 280);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [restaurantById, categoryCounts]);

  const scrollNavigationToButton = (buttonElement: HTMLButtonElement, navigationElement: HTMLDivElement | null) => {
    if (navigationElement && buttonElement) {
      const buttonOffsetLeft = buttonElement.offsetLeft;
      const navScrollLeft = navigationElement.scrollLeft;
      const navWidth = navigationElement.offsetWidth;
      const buttonWidth = buttonElement.offsetWidth;

      if (buttonOffsetLeft < navScrollLeft) {
        navigationElement.scrollTo({
          left: buttonOffsetLeft,
          behavior: 'smooth',
        });
      } else if (buttonOffsetLeft + buttonWidth > navScrollLeft + navWidth) {
        navigationElement.scrollTo({
          left: buttonOffsetLeft + buttonWidth - navWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const seatId = sessionStorage.getItem('seatId');
      if (!seatId) {
        const homeLink = queryString.stringifyUrl({
          url: '/',
          query: queryParams,
        });
        navigate(homeLink);
        return;
      }
      if (id) {
        const newRestaurantByID: any = findRestaurantById(id);
        const table = sessionStorage.getItem('seatId') as string;
        orderDetails.restaurant_id = id;
        orderDetails.order_table_number = table;
        orderDetails.seat_type = seatInformation.seat_type;
        orderDetails.seat_number = seatInformation.seat_number;

        if (newRestaurantByID !== undefined && !loadingFoods && !loading) {
          const updatedRestaurant = listRestaurantWithFood.find((restaurant) => restaurant.id === id);
          setRestaurantById(updatedRestaurant);
        } else if (newRestaurantByID === undefined && !loadingFoods && !loading) {
          const homeLink = queryString.stringifyUrl({
            url: '/',
            query: queryParams,
          });
          navigate(homeLink);
        }
      }
    };

    fetchData();
  }, [id, loadingFoods, listRestaurantWithFood]);

  useEffect(() => {
    if (orderDetails.foods.length > 0) {
      setShowCart(true);
      const updatedActiveItems: { [key: string]: number } = {};
      orderDetails.foods.forEach((food) => {
        const key = `${food.item_food_id}_${food.item_category_id}`;
        updatedActiveItems[key] = food.item_quantity;
      });

      setActiveFood(updatedActiveItems);
    } else {
      setActiveFood({});
      setShowCart(false);
    }
  }, [orderDetails, formDataDish]);

  useEffect(() => {
    if (formDataDish) {
      setShowCart(true);
      addToCart(formDataDish);
    }
  }, [formDataDish]);

  if (loading || !restaurantById) {
    return <Loader />;
  }

  const getRestaurantById = (id: string) => listRestaurantData.find((i) => i.id === id);

  const handleOnClick = () => {
    const promotionRestaurant = getRestaurantById(restaurantById.promotion_restaurant_id);
    if (
      restaurantById.isStadiumPromotion &&
      restaurantById.promotion_restaurant_id !== '' &&
      restaurantById.promotion_restaurant_id !== restaurantById.id &&
      promotionRestaurant &&
      isRestaurantOnline(promotionRestaurant)
    ) {
      setOpenPromotionPopup(true);
    } else {
      const { foodCount, drinkCount } = calculateFoodDrinkCount(orderDetails.foods);

      if (checkFoodDrinkLimit(foodCount, drinkCount)) return;
      handleDelivery();
    }
  };

  const handleCategoryClick = (
    categoryTitle: string,
    categoryElement: HTMLElement | null,
    buttonElement: HTMLButtonElement,
    navigationElement: HTMLDivElement | null
  ) => {
    activeCategoryRef.current = categoryTitle;
    if (categoryElement) {
      window.scrollTo({
        top: categoryElement.offsetTop + 200,
        behavior: 'smooth',
      });
    }

    if (buttonElement && navigationElement) {
      scrollNavigationToButton(buttonElement, navigationElement);
    }
  };

  return (
    <div className="min-h-screen flex w-full justify-center bg-black-0">
      <div className="w-full max-w-2xl bg-white h-full min-h-screen">
        <div className="fixed w-full max-w-2xl z-[3]" style={{ opacity: opacityBg }}>
          {restaurantById && (
            <div
              className="h-[140px] bg-no-repeat bg-contain p-4"
              style={{ backgroundImage: `url(${restaurantById.restaurant_profile_pic})`, backgroundPosition: 'center' }}
            >
              <button onClick={handleBackTolistRestaurant} className="bg-black-0 opacity-60 rounded-lg text-white h-9 w-9 flex justify-center items-center">
                <ArrowBackIosNewOutlinedIcon fontSize="small" />
              </button>
            </div>
          )}

          <div className="p-4 flex flex-col gap-1">
            {restaurantById && (
              <>
                <p className="text-gray-800 text-2xl not-italic leading-8 font-semibold">{restaurantById.name}</p>
                {stadiumData.enable_sales_count && (
                  <div className="text-gray-500 text-xs font-medium not-italic leading-[18px] flex gap-2">
                    <p className="font-light">{t('HOME_SOLD_ITEM', { item: restaurantById.sold })}</p>
                    <p>|</p>
                    <LocationIcon />
                    <p>
                      {restaurantById.region} {t('ZONE')}
                    </p>
                  </div>
                )}
                <div className="flex gap-1 flex-wrap">
                  {restaurantById &&
                    restaurantById.categories
                      .filter((category) => categoryCounts[category.title] > 0)
                      .sort((a, b) => a.order - b.order)
                      .slice(0, 4)
                      .map((category, index) => (
                        <p
                          key={index}
                          className="text-green-700 text-xs not-italic font-medium leading-[18px] rounded-2xl bg-[#F2FBF3] px-2 py-[2px] max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                        >
                          {category.title}
                        </p>
                      ))}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={`relative z-[5] ${stadiumData.enable_sales_count ? 'h-[300px]' : 'h-[240px]'}`}>
          <div className="flex fixed w-full max-w-2xl bg-white gap-2 items-center px-4 shadow-search" style={{ opacity: opacityAfter }}>
            <button className="p-3" onClick={handleBackTolistRestaurant}>
              <ArrowLeft />
            </button>
            <p className="text-gray-700 text-base font-medium leading-6">{restaurantById && restaurantById.name}</p>
          </div>
        </div>

        <div
          className="flex sticky z-[5] top-[43px] bg-white border-t w-full overflow-scroll no-scrollbar gap-1 py-2 px-4 border-gray-200 shadow-search"
          ref={navigationRef}
        >
          {restaurantById &&
            restaurantById.categories
              .filter((category) => categoryCounts[category.title] > 0)
              .sort((a, b) => a.order - b.order)
              .map((category) => (
                <button
                  data-category={category.title}
                  key={category.id}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    const categoryElement = itemsRef.current[category.title];
                    const buttonElement = e.currentTarget as HTMLButtonElement;

                    handleCategoryClick(category.title, categoryElement, buttonElement, navigationRef.current);
                  }}
                  className={`w-fit py-2 px-4 text-sm font-semibold leading-5 whitespace-nowrap rounded-md ${
                    activeCategoryRef.current === category.title ? 'bg-[#F2FBF3] hover:text-green-700 text-green-700' : ' text-gray-500'
                  }`}
                >
                  {category.title}
                </button>
              ))}
        </div>

        <div className={`flex-1 overflow-y-auto p-4 relative z-[4] bg-white ${showCart ? 'mb-[60px]' : ''} `}>
          {restaurantById &&
            restaurantById.categories
              .filter((category) => categoryCounts[category.title] > 0)
              .sort((a, b) => a.order - b.order)
              .map((category) => (
                <div key={category.id}>
                  <h2 className="text-gray-500 text-base not-italic font-medium leading-6 pt-4" ref={(el) => (itemsRef.current[category.title] = el)}>
                    {category.title} ({categoryCounts[category.title]})
                  </h2>
                  <div className="grid grid-cols-1 gap-4">
                    {foods &&
                      foods
                        .filter((food) => food.categories.includes(category.id))
                        .map((food) => (
                          <div
                            key={food.id}
                            className="py-4 border-b border-gray-200"
                            onClick={() => {
                              if (activeFood[`${food.id}_${category.id}`]) {
                                editItemOrder(food, category.id);
                              } else {
                                toggleCart(food, category.id);
                              }
                            }}
                          >
                            <div className="w-full flex items-end justify-between gap-2 relative">
                              <div className="flex items-center flex-grow">
                                <img alt="image_url" className="rounded-lg w-[100px] h-[100px] object-cover flex-none" src={food.image_url} />
                                <div className="flex flex-col gap-1 overflow-hidden ml-4 max-w-[72%]">
                                  <p className="max-h-[3em] overflow-hidden text-ellipsis">{food.name}</p>
                                  <div className="flex gap-1 text-[10px] not-italic leading-[15px]">
                                    <p className="font-light whitespace-nowrap">{t('HOME_SOLD_ITEM', { item: food.sold })}</p>
                                    <p className="text-gray-300">|</p>
                                    <p className="text-gray-400 max-h-[3em] overflow-hidden text-ellipsis whitespace-pre-line line-clamp-2">{food.description}</p>
                                  </div>
                                  <p className="text-green-500 font-medium">¥ {formatPriceItem(food.price)}</p>
                                </div>
                              </div>
                              {activeFood[`${food.id}_${category.id}`] ? (
                                <div className="flex w-auto gap-3 items-center justify-end flex-shrink-0 absolute right-0">
                                  {food.enabled_note && (
                                    <button className="w-auto flex gap-1 rounded-lg py-1 px-2 border border-neutral-300 items-center h-[24px]">
                                      <NoteIcon /> <p className="text-black-700 font-semibold text-xs leading-[18px]">{t('MENU_BUTTON_NOTE')}</p>
                                    </button>
                                  )}
                                  <div onClick={(e) => e.stopPropagation()}>
                                    <ItemCounter
                                      count={findQuantityInOrderDetail(food.id, category.id)}
                                      setCount={(newCount) => handleSetCount(newCount, food.id, category.id)}
                                      initialCountItem={0}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <button className="w-7 bg-green-500 rounded-md border border-green-600 absolute right-0">
                                  <AddOutlinedIcon className="text-white" style={{ fontSize: 26 }} />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}

                    <Drawer anchor={'right'} transitionDuration={600} open={openPromotionPopup} onClose={toggleDrawerOpenPromotionPopup(false)}>
                      <AddPromotion
                        toggleDrawer={toggleDrawerOpenPromotionPopup}
                        handleDelivery={handleDelivery}
                        promotion_restaurant_id={restaurantById.promotion_restaurant_id}
                      />
                    </Drawer>
                    <Drawer anchor={'right'} transitionDuration={600} open={openCustomDish} onClose={toggleDrawer(false)}>
                      <CustomizeDish toggleDrawer={toggleDrawer} onSubmit={handleFormSubmitDish} dishData={customDishData} />
                    </Drawer>
                    {showCart && (
                      <animated.div
                        style={cartAnimation}
                        className="fixed flex gap-1 justify-between items-center bottom-4 w-full sm:max-w-[93%] max-w-[640px] bg-white py-2 px-3 rounded-[1000px] border border-green-200"
                      >
                        <div className="flex gap-2 items-center w-[65%]">
                          <ShoppingIcon className="w-6 h-6" />
                          <div className="not-italic">
                            <p className="text-green-700 font-semibold text-sm leading-5">
                              {FoodTotalCount} {t('MENU_CARD_ITEMS')}
                            </p>
                            <p className="text-gray-500 text-[10px] font-normal leading-[18px] truncate max-w-[150px]">
                              {t('ORDER_STATUS_TITLE')}: {restaurantById.name}
                            </p>
                          </div>
                        </div>
                        <button onClick={handleOnClick} className="rounded-[1000px] w-max flex items-center bg-green-500 text-white text-sm pl-3 pr-2 py-2">
                          <span className="whitespace-nowrap">¥ {formatPriceItem(TotalOrderPrice)}</span>
                          <KeyboardArrowRightIcon className="text-gray-200" />
                        </button>
                      </animated.div>
                    )}
                    <Dialog open={openModal} onClose={handleCloseModal} sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}>
                      <div className="p-6 flex flex-col gap-6">
                        <div className="flex justify-end">
                          <ClearIcon className="cursor-pointer" onClick={handleCloseModal} />
                        </div>
                        <div className="flex flex-col gap-1">
                          <p className="text-black-900 text-lg font-semibold leading-7">{t('MENU_MODALBACK_TITLE')}</p>
                          <p className="text-black-600 text-sm font-normal leading-5">{t('MENU_MODALBACK_DESCRIPTION')}</p>
                        </div>
                        <div className="flex flex-col gap-6">
                          <Button onClick={handleNavigateOrder} type="secondary">
                            {t('MENU_MODALBACK_BUTTON_CONFIRM')}
                          </Button>
                          <Button onClick={handleCloseModal} type="primary">
                            {t('MENU_MODALBACK_BUTTON_CANCEL')}
                          </Button>
                        </div>
                      </div>
                    </Dialog>
                    <Dialog
                      open={openDialog.value}
                      slotProps={{
                        backdrop: {
                          sx: { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                        },
                      }}
                      sx={{ '& .MuiPaper-root': { borderRadius: '12px', width: '100%' } }}
                    >
                      {openDialog.value && (
                        <div className="p-6 flex flex-col gap-1 w-full items-center">
                          <p
                            className="text-black-900 text-lg font-semibold leading-7 text-center"
                            dangerouslySetInnerHTML={{ __html: t('MAXIMUN_QUANTITY', { tag: openDialog.type === 'drink' ? t('DRINK') : t('FOOD') }) }}
                          />
                          <p className="text-black-900 text-lg font-semibold leading-7 text-center">
                            {openDialog.type === 'drink' ? t('DRINK_MAX', { num: max_drink_quantity }) : t('FOOD_MAX', { num: max_food_quantity })}
                          </p>
                          <p className="text-black-900 text-lg font-semibold leading-7 text-center">{t('PLEASE_TRY_AGAIN')}</p>
                          <button
                            className="px-18 mt-2 py-2.5 rounded-lg border border-solid shadow-button text-base font-semibold leading-6 bg-primary-green border-primary-green text-white"
                            onClick={handleCloseDialog}
                          >
                            {t('OK')}
                          </button>
                        </div>
                      )}
                    </Dialog>
                  </div>
                </div>
              ))}
        </div>
        <div className="w-full flex gap-1 items-center justify-center mb-3">
          <p className="text-gray-700 text-sm font-medium leading-5">{t('POWERED_BY')}</p>
          <TabilifeLogo width={100} height={20} />
        </div>
      </div>
    </div>
  );
};

export default Menu;
