import { useQuery } from 'react-query';
import { BASE_URL } from '../configs/config';

const useGetListUsers = () => {
  let url = `${BASE_URL}get_all_gender_users`;
  return useQuery(
    url,
    async () => {
      try {
        const response = await fetch(url);
        return response.json();
      } catch (error) {}
    },
    { retry: 2 }
  );
};

export default useGetListUsers;
