import React from 'react';
import { Order } from '../../../../../interfaces/types';
import OrderItem from './OrderItem';
import { useMenuContext } from '../../../../../context/MenuContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOrderContext } from '../../../../../context/OrderContext';
import { BillStatus, OrderItemStatus } from '../../../../../consts';
import queryString from 'query-string';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isRestaurantOnline } from '../../../../../utils';
import { ReactComponent as TabilifeLogo } from '../../../../../assets/icon/tabi-life-black.svg';

const OrderList: React.FC<{ orders: Order[] }> = ({ orders }) => {
  const { listRestaurantData, seatInformation } = useMenuContext();
  const { updateOrderDetails } = useOrderContext();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { t } = useTranslation();

  const onShowDetail = (item: Order) => {
    const orderDetailLink = queryString.stringifyUrl({
      url: `/orderdetail/${item.id}`,
      query: queryParams,
    });
    navigate(orderDetailLink);
  };

  const onReorder = (item: Order) => {
    const restaurant = listRestaurantData.find((restaurant) => restaurant.id === item.restaurant_id);
    if (restaurant && !isRestaurantOnline(restaurant)) {
      setDialogOpen(true);
      return;
    }
    const seatId = sessionStorage.getItem('seatId') || '';
    updateOrderDetails({
      bill_status: BillStatus.NOT_CLEARED,
      foods: item.foods.map((food) => {
        const { item_deleted_quantity, ...rest } = food;
        return rest;
      }),
      promotion_foods: item.promotion_foods.map((food) => {
        const { item_deleted_quantity, ...rest } = food;
        return rest;
      }),
      order_status: OrderItemStatus.PREPARING,
      order_table_number: seatId,
      seat_type: seatInformation.seat_type,
      seat_number: seatInformation.seat_number,
      payment_method: '',
      restaurant_id: item.restaurant_id,
    });

    const deliveryDetailLink = queryString.stringifyUrl({
      url: `/delivery`,
      query: queryParams,
    });
    navigate(deliveryDetailLink);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div className="flex flex-col gap-3 flex-1 overflow-auto shadow-search no-scrollbar bg-[#F9FAFB]">
      {orders.map((order) => {
        const restaurant = listRestaurantData.find((restaurant) => restaurant.id === order.restaurant_id);
        return <OrderItem key={order.id} order={order} onShowDetail={onShowDetail} onReorder={onReorder} restaurant={restaurant} />;
      })}
      <div className="w-full flex gap-1 items-center justify-center mb-3">
        <p className="text-gray-700 text-sm font-medium leading-5">{t('POWERED_BY')}</p>
        <TabilifeLogo width={100} height={20} />
      </div>
      <Dialog open={dialogOpen} onClose={handleCloseDialog} sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}>
        <div className="p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <p className="text-black-900 text-lg font-semibold leading-7">{t('LISTRESTAURANT_MODAL_TITLE')}</p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OrderList;
