import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { Drawer, Dialog } from '@mui/material';
import PAYMENT from '../../DeliveryInformation/components/Payment';
import { SHIPPING_FEE } from '../../../consts';
import useCreateOrder from '../../../hook/useCreateOrder';

interface PaymentBacklogProps {
  orderDetail: any;
}

const PaymentBacklog: React.FC<PaymentBacklogProps> = ({ orderDetail }) => {
  const { t } = useTranslation();
  const [openPayment, setOpenPayment] = useState(false);
  const { checkOrder } = useCreateOrder();
  const [openError, setOpenError] = useState(false);
  const [errorFood, setErrorFood] = useState<string>('');

  const [totalPrice] = useMemo(() => {
    if (!orderDetail) return [0];

    const totalPrice =
      (orderDetail.foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0) +
      (orderDetail.promotion_foods?.reduce((total: any, food: any) => total + (parseFloat(food.item_subtotal) || 0), 0) || 0);
    const formattedPrice = totalPrice + SHIPPING_FEE;
    return [formattedPrice];
  }, [orderDetail]);

  const handlePlaceOrder = async () => {
    const isErrorOrder = await checkOrder(orderDetail);
    if (isErrorOrder) {
      setOpenPayment(true);
    } else {
      const errorFoodItem = sessionStorage.getItem('ErrorFood');
      if (errorFoodItem) {
        setErrorFood(errorFoodItem);
      }
      setOpenError(true);
    }
  };

  const toggleDrawerPayment = (newOpen: boolean) => () => {
    setOpenPayment(newOpen);
  };

  const handleCloseErrorModal = () => {
    setOpenError(false);
    sessionStorage.removeItem('ErrorFood');
  };

  return (
    <div className="flex flex-col gap-2 items-center">
      <p className="text-black-500 font-normal text-sm leading-5">{t('CONTINUE_PAY')}</p>
      <Button onClick={handlePlaceOrder} type="submit">
        {t('COMPLETE_PAYMENT')}
      </Button>
      <Drawer anchor={'right'} transitionDuration={600} open={openPayment} onClose={toggleDrawerPayment(false)}>
        <PAYMENT toggleDrawer={toggleDrawerPayment} totalPrice={totalPrice} orderId={orderDetail.id} restaurant_id={orderDetail.restaurant_id} />
      </Drawer>
      <Dialog open={openError} onClose={handleCloseErrorModal} sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}>
        <div className="p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <p className="text-black-900 text-lg font-semibold leading-7">{errorFood ? `${errorFood} ${t('HAS_BEEN_DISABLE')}` : t('LISTRESTAURANT_MODAL_TITLE')}</p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PaymentBacklog;
